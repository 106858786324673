<template>
  <!-- this is buy dashboard page, shows 3 Status Cards and 2 divs-->
  <div>
    <!-- <div>
      <TheTitleBar
        sectionTitle="dashboard.label.mnuDashboard"
        sectionID="sellerListings"
        :showCount="false"
        :showValue="false"
        :showFilters="false"
        :showSort="false"
        :showSellButton="false"
        :totalCount="0"
        :totalValue="0"
      />
    </div> -->

    <!-- buying card row -->

    <div class="dashboard-status-card-wrapper mx-auto">
      <template v-if="loading">
        <div
          class="item"
          v-for="(item, index) in new Array(3)"
          :key="index"
        >
          <v-skeleton-loader
            class="mx-auto my-2 px-2"
            max-width="100%"
            type="article"
            loading
          />
        </div>
      </template>
      <div
        class="item"
        v-for="(card, key) in $store.getters.buyerDashboardCard"
        :key="key"
        v-else
      >
        <TheDashboardStatusCard
          :card="card"
          :icon="key"
          :background="true"
          userType="buyer"
        />
      </div>
    </div>
<!--
    <div class="refer-link">
      <img src="@/assets/img/dashboard/buyerdashboard-referal.png" />
      <div class="refer-link-desc">
        <div class="refer-link-desc-title">{{ $t("dashboard.label.referTitle") }}</div>
        <div class="refer-link-desc-subtitle">{{ $t("dashboard.label.referText") }}</div>
        <div class="refer-link-refer_holder">
          <input :value="userRef" readonly class="refer-link-refer_holder-input" />
          <v-btn
            @click="copyRefer"
            color="primary"
            depressed
            class="refer-link-refer_holder-btn"
          >
            {{ $t("dashboard.label.referCopy") }}
          </v-btn>
        </div>
      </div>
    </div>
-->
    <!-- <b-row>
        <b-card-group deck class="m-auto">
          <b-card class="text-center" style="max-width: 500px">
            <img src="@/assets/img/dashboard/car_listing.jpg" alt="" />
            <h4>{{ $t("listings.label.noListing") }}</h4>
            <h6 class="text-half-dark pb-3">
              {{ $t("listings.label.noListingText") }}
            </h6>
          </b-card>

          <b-card class="text-center" style="max-width: 500px">
            <img src="@/assets/img/dashboard/payment.jpg" alt="" />
            <h4>{{ $t("listings.label.yourPayments") }}</h4>
            <h6 class="text-half-dark pb-3">
              {{ $t("listings.label.yourPaymentsText") }}
            </h6>
          </b-card>
        </b-card-group>
      </b-row> -->
  </div>
</template>
<script>
// import TheTitleBar from "@/components/dashboard/global/TheTitleBar.vue";
import TheDashboardStatusCard from "@/components/dashboard/global/TheDashboardStatusCard.vue";

export default {
  components: {
    // TheTitleBar,
    TheDashboardStatusCard,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    userRef() {
      return `${window.location.origin}?ref=${this.user.Ref}`;
    },
  },
  methods: {
    async getInfo() {
      try {
        this.loading = true;
        let parameter = {
          date: 1,
          range: 1,
        };
        await this.$store.dispatch("getBuyerInfo", parameter);
      }finally {
        this.loading = false;
      }
    },
    async copyRefer() {
      try {
        await navigator.clipboard.writeText(this.userRef);
        this.$store.dispatch("showToast", {
          content: "Refer Copied Success",
          type: "success",
        });
      } catch ($e) {
        this.$store.dispatch("showToast", {
          content: "Refer Copied Fail",
          type: "error",
        });
      }
    },
  },
  beforeMount() {
    this.getInfo();
    this.$store.dispatch('setDashboardType', 'BuyerDashboard')
  },
};
</script>
<style lang="scss" scoped>
.refer-link {
  display: flex;
  align-items: stretch;
  height: 342px;
  flex: none;
  margin-top: 24px;
  &-desc {
    background-color: #fff;
    flex: 1;
    border-radius: 0 0 4px 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 60px 24px;
    &-title {
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      font-family: "Poppins", sans-serif;
      color: #363636;
    }
    &-subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #363636;
      margin-top: 24px;
    }
  }
  &-refer_holder {
    display: flex;
    align-items: stretch;
    width: 425px;
    height: 85px;
    margin-top: 48px;
    &-input {
      box-sizing: border-box;
      border-radius: 30px 0 0 30px;
      padding: 12px 24px;
      border: 1px solid #d0d0d0;
      border-right: none;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      height: 48px;
      outline: none;
      color: #acacac !important;
      &::placeholder {
        color: #acacac !important;
        font-size: 12px;
      }
    }
    &-btn {
      border-radius: 0 30px 30px 0;
      height: 48px !important;
      text-transform: none;
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0;
      padding: 0 10px;
    }
  }
}
.dashboard-status-card-wrapper {
  display: flex;
  column-gap: 24px;
  row-gap: 24px;
  flex-wrap: wrap;
  padding-top: 24px;
  .item {
    width:100%;
    @media screen and (min-width:960px) {
      width: calc((100% / 3) - 16px);
    }
  }
  @media (max-width: 768px) {
    padding: 24px 10px;
    .item {
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
.refer-link-refer_holder-btn {
  .v-btn__content {
    font-family: "Inter", sans-serif !important;
  }
}
</style>
